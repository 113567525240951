import React from "react";
import "../css/Main.css";
import Dagger2 from "../images/Dagger.png";
import Daggers from "../images/Daggers X Green.png";

function Aftercare() {
  return (
    <div className="is-preload">
      <div id="wrapper">
        <div id="main">
          <article id="aftercare">
            <div className="button-container">
              <button id="closeBtn">
                <a id="closeBtnA" href="/">
                  <img src={Daggers} alt="Close" />
                </a>
              </button>
            </div>
            <h2 className="major">Aftercare</h2>
            <span className="image main">
              <img src={Dagger2} alt="logo" />
            </span>
            <h2>All Procedures</h2>
            <h3>Immediately After 3 days</h3>
            <ol>
              <li>
                Wrap ice in clear freezer bag and ice area 10 minutes on/off
                throughout the day to decrease swelling
              </li>
              <li>
                <b>
                  No smoking, alcohol, recreational drug use, or exercise that
                  causes sweating & nothing should be used on pigmented areas
                  unless directly specified(no makeup, glycolic acids, Retin-A
                  sunblock, etc.). Keep out of direct sun, no fake tanning or
                  spray tanning on tattooed areas. No submersion in bodies of
                  water, no Jacuzzis, no saunas. Do not sleep on tattooed area
                  to prevent sticking to bed sheets and make sure bed sheets are
                  freshly cleaned. No pets allowed in bed while healing. No
                  touching area with dirty fingers or anything else that isn't
                  clean(Lips: drink through straws and small bites only with
                  fork/spoon, no kissing).
                </b>
              </li>
              <li>
                Take a good multivitamin, vitamin C, and eat a nutritional diet
                while healing for best results. (Restrict processed foods and
                high carbs and maintain a healthy intake of protein and
                calories. Don't skip meals.) Do not use any topical antibiotics
                during healing.
              </li>
              <li>
                Thoroughly clean tattooed area 3 times daily with wet lathered
                soap (sensitive skin or antimicrobial), cool water and clean
                hands or quality paper towel. It will still feel tender but it
                needs to be cleaned properly. Use gentle circular massaging
                movements with hands/paper towel and no loofas, scrubbing or
                scratching. During showers, water can get on tattooed area if
                cleaning, otherwise keep it away. Also, keep showers to fewer
                than 10 minutes and they should not steam.
              </li>
              <li>
                <b>
                  Thoroughout the day, do not let plasma or wound exudate dry on
                  surface of tattooed area as this will lead to scabbing and
                  patchy healing. Lightly clean area with either pre-moistened
                  baby wipes(conatining NO llotions or aloe) or a moistened
                  quality paper towel every hour if needed. If the area is shiny
                  wet, it needs to be dabbed clean.
                </b>
              </li>
              <li>
                After there is no longer any plasma or exudate secretion for 6
                hours (anythime from day 1-3), you can begin applying a samll
                amount of Redemption or Aquaphor 2-3 times daily. Allow area to
                completely dry for 2 minutes after properly cleaning, prior to
                ointment application.
              </li>
              <li>
                Thoroughly and gently massage ointment into the area witha clean
                Q-tip, microbrush or finger. Lightly dab off excess with clear
                paper towel.
              </li>
            </ol>
            <h3>3 days - 7 days</h3>
            <ol>
              <li>
                Continue all No's from Immediately After, though alcohol may be
                consumed in low/responsible amounts.
              </li>
              <li>
                Continue cleaning and applying ointment 2-3 times daily
                depending on dryness.
              </li>
              <li>
                Do not pick or pull at any flaking skin during this phase as
                this will lead to pigment loss and possible infection.
              </li>
            </ol>
            <h3>After 1 Week</h3>
            <ol>
              <li>
                Can resume use of Aspirin, Ibuprofen, NSAIDs, Vitamin E, Fish
                Oil, Omegas, suplements, hormones, steroids, and blood thinners.
              </li>
              <li>
                Continue cleaning and applying ointment, once in the morning and
                once before bed until fully healed when there is no longer any
                flaking skin and the tissue elasticity of the area has returned
                to normal (about 2-6 weeks depending on area).
              </li>
              <li>
                Reduce ointment use to once daily if any pimples appear in or
                next to the tattooed area. Be careful not to apply ointment to
                pimple/s.
              </li>
            </ol>
            <h3>After Completely Healed/Long Term</h3>
            <ol>
              <li>
                Area is completely healed only when there is absolutely no more
                flaking skin and collagen elasticity has completely returned to
                the area. Don't rush your heal.
              </li>
              <li>
                Use of other medications, supplements, lotions & cleaners,
                waxing, electrolysis, lash & brow dyes, makeup, etc. can be
                resumed.
              </li>
              <li>
                Tanning, chemical peels and IPL can be resumed to areas that are{" "}
                <b>NOT</b> tattooed. Tell technician/dermatologist that you have
                permanent makeup, etc. when receiving treatments and to avoid
                those areas by a finger width space.
              </li>
              <li>
                Laser hair removal can <b>NEVER</b> be resumed over any tattooed
                area as it can remove or discolor pigment.
              </li>
              to keep your procedure looking its best for teh longest time, take
              care of it! Wear good sunblock and keep it on every couple of
              hours when in the sun. Take care of your skin. The healthier your
              skin, the better your tattoo will look. Stay hydrated, use daily
              moisturizer.
            </ol>
          </article>
        </div>
      </div>
    </div>
  );
}

export default Aftercare;
