/** Rise in Glory Frontend API */
import axios from "axios";

// const BASE_URL = "http://localhost:3001";
const BASE_URL = "https://j9j65ulfol.execute-api.us-east-2.amazonaws.com/main";
const API_KEY = process.env.REACT_APP_API_KEY;

class RiseInGloryApi {
  static async request(endpoint, data = {}, method = "get") {
    const url = `${BASE_URL}/${endpoint}`;
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": API_KEY,
    };

    // Send the data object as the body
    const body = { body: JSON.stringify(data) };

    try {
      return (await axios({ url, method, headers, data: body })).data;
    } catch (err) {
      console.error("API Error:", err);
      throw new Error("Failed to communicate with the server");
    }
  }

  static async sendEmail(data) {
    try {
      let res = await this.request("contact", data, "POST");
      return res.data;
    } catch (error) {
      console.error("Error details:", error.response || error.message);
      throw new Error("Failed to send email");
    }
  }
}

export default RiseInGloryApi;
