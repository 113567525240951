import React, { useEffect, useRef } from "react";
import "../css/Main.css";
import Daggers from "../images/Daggers X Green.png";
import HeartOfThorns from "../images/Heart of Thorns.png";

function Services() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5;
      videoRef.current.play();
    }
  }, []);

  return (
    <div className="is-preload">
      <div id="wrapper">
        <div id="main">
          <article id="services">
            <div className="button-container">
              <button id="closeBtn">
                <a id="closeBtnA" href="/">
                  <img src={Daggers} alt="Close" />
                </a>
              </button>
            </div>
            <h2 className="major">Services and Pricing</h2>
            <span className="image main">
              <img src={HeartOfThorns} alt="Heart" />
            </span>
            <h3>Consultations</h3>
            <p>
              Please review Pre care and Contraindications before inquiring for
              Permanent Makeup or Email me (kayleet.mua@gmail.com)
            </p>
            <p>PREVIOUS TATTOO WORK- PLEASE ADVISE:</p>
            <p>
              If you have previous permanent makeup, I require a consultation
              before booking an appointment.{" "}
            </p>
            <p>
              Since I do not touchup other artists’ work, if you have previous
              permanent makeup by another artist, you will be charged first
              session pricing at a minimum.{" "}
            </p>
            <table>
              <thead>
                <tr>
                  <th>1st Session</th>
                  <th>COSMETIC TATTOO SERVICES:</th>
                  <th>Touch-up</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>$500</td>
                  <td>NANO BROWS</td>
                  <td>$250</td>
                </tr>
                <tr>
                  <td>$500</td>
                  <td>POWDER BROWS</td>
                  <td>$250</td>
                </tr>
                <tr>
                  <td>$500</td>
                  <td>LIP BLUSH</td>
                  <td>$250</td>
                </tr>
                <tr>
                  <td>$250</td>
                  <td>LASH ENCHANCEMENT</td>
                  <td>$125</td>
                </tr>
                <tr>
                  <td>$500</td>
                  <td>EYELINER</td>
                  <td>$250</td>
                </tr>
                <tr>
                  <td>$150+</td>
                  <td>FRECKLES</td>
                  <td>$150+</td>
                </tr>
              </tbody>
            </table>

            <table>
              <thead>
                <tr>
                  <th>1st Session</th>
                  <th>PARAMEDICAL SERVICES:</th>
                  <th>Touch-up</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>$650+</td>
                  <td>AREOLA REPIGMENTATION</td>
                  <td>$350+</td>
                </tr>
                <tr>
                  <td>Inquire for pricing</td>
                  <td>SCAR CAMOUFLAGE</td>
                  <td>Inquire for pricing</td>
                </tr>
              </tbody>
            </table>

            <table>
              <thead>
                <tr>
                  <th>Additional Services</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>TRADITIONAL MAKEUP</td>
                  <td>Inquire for pricing</td>
                </tr>
                <tr>
                  <td>BROW TINT</td>
                  <td>Inquire for pricing</td>
                </tr>
                <tr>
                  <td>BROW LAMINATION</td>
                  <td>Inquire for pricing</td>
                </tr>
              </tbody>
            </table>
          </article>
        </div>
      </div>
    </div>
  );
}

export default Services;
