import React from "react";
import "../css/Main.css";
import SacredHeart from "../images/Sacred Heart.png";
import Daggers from "../images/Daggers X Green.png";

function PreCare() {
  return (
    <div className="is-preload">
      <div id="wrapper">
        <div id="main">
          <article id="precare">
            <div className="button-container">
              <button id="closeBtn">
                <a id="closeBtnA" href="/">
                  <img src={Daggers} alt="Close" />
                </a>
              </button>
            </div>
            <h2 className="major">Pre-Care</h2>
            <span className="image main">
              <img src={SacredHeart} alt="Heart" />
            </span>
            <p>
              In order for micropigmentation to heal properly, one must follow
              the following Pre-Care Instructions. Pre-Care instructions are
              designed to limit bleeding and skin sensitivities during the
              service. Excessive bleeding during the procedure will dilute the
              pigment color and lead to poor healed results. Pigment when mixed
              with blood will change the color results, appearing more
              ashy/purple.
            </p>
            <ol>
              <li>
                Do not workout 48hrs before the procedure.
                <div>
                  - The body can burn for up to 36 hours after a workout, and
                  the body heat expands the pores making it harder to retain
                  pigment.
                </div>
              </li>
              <li>
                No alcohol, Aspirin, Ibuprofen, Naproxen, or Pepto-Bismol 48
                hours before the treatment.
                <div>- May cause thinning of the blood.</div>
              </li>
              <li>
                No caffeine the day of the treatment- it may make the client
                jittery.
                <div>
                  - Although caffeine is not a blood thinner, it will increase
                  blood pressure which can cause more bleeding.
                </div>
              </li>
              <li>
                Avoid sun and tanning on the face 2 weeks prior to the procedure
                <div>- I cannot work on compromised skin.</div>
              </li>
              <li>
                Avoid fish oil, vitamins, nutritional shakes, and
                “Hair-Skin-Nails” supplements
                <div>- They can increase bleeding time or thin the blood</div>
              </li>
              <li>
                Discontinue Retin-A, Chemical & Laser peels, Microdermabrasion,
                Alpha Hydroxy Acid (AHA) and chemical exfoliants or
                “brightening” skincare at least 6 weeks prior to procedure. Once
                permanent makeup is healed, avoid use of these products near
                that area for prevention of premature fading.){" "}
              </li>
              <li>
                Botox and filler should be done 3 weeks prior or 2 months after
                procedure.
                <div>
                  - Symmetry can be hard to balance with too recent of Botox.
                </div>
              </li>
              <li>
                No waxing, tinting, or threading 1 week prior to the procedure.
                <div>
                  - The more natural more natural hair you have the better. Also
                  waxing may cause a breakout.
                </div>
              </li>
              <li>
                PLEASE NOTE: one may be more sensitive during their menstrual
                cycle!
              </li>
              <li>
                Clients who smoke THC or cigarettes will bleed more which will
                lead to poor retention and slow healing wounds.
              </li>
            </ol>

            <h4>Contraindications</h4>
            <ul>
              <li>Epilepsy</li>
              <li>Diabetic</li>
              <li>Chemotherapy</li>
              <li>Sick (cold, flu, ect.)</li>
              <li>Pergnant or Nursing</li>
              <li>Major Heart Problems</li>
              <li>Viral Infetions/Disease</li>
              <li>
                Botox (I recomend discontinuing Botox 1 month prior and Post
                procedure)
              </li>
              <li>Accutane in the past year</li>
              <li>Chemical peel or laser treatment within the past month</li>
              <li>Psoriasis, Rash, or Sunburn on or around treated area</li>
              <li>An allergry to Lidocane, Tetracaine Epinephrine</li>
            </ul>

            <p>
              Attention Please: I strongly advise prospective new clients to
              book their appointments at least 3-4 months in advance of
              Vacations, Weddings, and Special Occasions. This allows adequate
              time for healing of both your Initial Appointment as well as the
              Follow Up Enhancement Session 5-6 weeks after your initial visit.
              Dream results cannot always be achieved in one session, this is a
              multiple-step process! Some clients may require more than two
              sessions to achieve their desire density and shape.
            </p>
          </article>
        </div>
      </div>
    </div>
  );
}

export default PreCare;
