import React from "react";
import "../css/Main.css";
import Daggers from "../images/Daggers X Green.png";
import Crown from "../images/Crown.png";

function Policies() {
  return (
    <div className="is-preload">
      <div id="wrapper">
        <div id="main">
          <article id="policies">
            <div className="button-container">
              <button id="closeBtn">
                <a id="closeBtnA" href="/">
                  <img src={Daggers} alt="Close" />
                </a>
              </button>
            </div>
            <h2 className="major">Policies</h2>
            <span className="image main">
              <img src={Crown} alt="" />
            </span>
            <p>
              BEING THAT THE LOUNGE IS LICENSED UNDER “BODY ART ESTABLISHMENT”
              NO ONE UNDER THE AGE OF 18 IS ALLOWED. DUE TO LIMITED SEATING
              CAPACITY, IT IS ENCOURAGED TO NOT BE ACCOMPANIED BY ADDITIONAL
              FRIENDS OR FAMILY.
            </p>
            <p>
              A VALID ID IS REQUIRED AT THE TIME OF YOUR SERVICE. IF YOU DO NOT
              HAVE A PHYSICAL VALID ID I CANNOT PROCEED WITH THE APPOINTMENT.
            </p>
            <ul>
              <li>
                Healed results will vary from person to person and using a
                powder or pencil may still be needed in some cases, because I
                have no control over what occurs during the healing process,
                absolutely NO guarantee will be made, and additional sessions
                may be needed to obtain optimal results. Additional charges will
                apply in these cases. Although I give my best efforts to provide
                you with the highest quality of service, many factors can result
                in less than desired outcome due to healing and aftercare.
                Please refer to my PRE CARE section to review everything you
                will need to know and prepare for before your procedure.
              </li>
              <li>
                A NON REFUNDABLE deposit is required to book your appointment,
                this deposit ensures you are serious about your appointment. The
                remaining balance will be due on the day of the appointment
                (prices are listed). Please make sure to double check the
                procedure date and time you are booking. You may RESCHEDULE your
                appointment up to 1 week before your scheduled time. If it is
                after the 1 week prior you will have to place a new deposit to
                reschedule. ALL CANCELLATIONS FORFEIT THEIR DEPOSIT. Booking
                Fees are nonrefundable and nontransferable.
              </li>
              <li>
                If you choose to cancel your appointment, you will automatically
                forfeit your Booking Fee (meaning that you will be required to
                pay a new booking fee when you schedule your new appointment.)
              </li>
              <li>
                The Remaining balance can be paid in cash, Zelle, Venmo or
                Cashapp.
              </li>
              <li>
                If you No-show your scheduled appointment or are more than 20
                minutes late, or if I am unable to work on you due to
                pre-procedure details or contraindications, you forfeit your
                deposit and have to pay another deposit to reschedule.
              </li>
              <li>
                These policies are non-negotiable and apply equally to everyone.
              </li>
            </ul>
            <p>
              PLEASE NOTE: Permanent Makeup is a multiple-step appointment
              process. Healed results will vary with each individual client, and
              due to the nature of the procedure, NO guarantees will be made.
              Additional sessions (at an additional charge) may be required to
              obtain optimal desired results. Although I give my best efforts to
              provide you with quality service, many factors can result in less
              than desired outcome as each client will heal differently. In
              order for your new tattoo to heal properly (and look their very
              best) following all Pre & Post Care Instructions is crucial! Pre
              Care Instructions are designed to limit bleeding and skin
              sensitivities during the service. Excessive bleeding during the
              procedure can dilute and expel pigment color and lead to poor
              results. Permanent Makeup is NOT meant to stay as dark as it
              initially appears immediately after procedure. It will gradually
              and continuously get lighter as your skin is always naturally
              regenerating and exfoliating itself. Annual color boost is
              recommended to keep your permanent makeup looking its best.
            </p>

            <h4>Contraindications</h4>
            <ul>
              <li>Epilepsy</li>
              <li>Diabetic</li>
              <li>Chemotherapy</li>
              <li>Sick (cold, flu, ect.)</li>
              <li>Pergnant or Nursing</li>
              <li>Major Heart Problems</li>
              <li>Viral Infetions/Disease</li>
              <li>
                Botox (I recomend discontinuing Botox 1 month prior and Post
                procedure)
              </li>
              <li>Accutane in the past year</li>
              <li>Chemical peel or laser treatment within the past month</li>
              <li>Psoriasis, Rash, or Sunburn on or around treated area</li>
              <li>An allergry to Lidocane, Tetracaine Epinephrine</li>
            </ul>
          </article>
        </div>
      </div>
    </div>
  );
}

export default Policies;
