import { Route } from "react-router-dom";
import Home from "./components/Home";
import Services from "./components/Services";
import PreCare from "./components/PreCare";
import Aftercare from "./components/Aftercare";
import Policies from "./components/Policies";
import Contact from "./components/Contact";

/** Routes for Website */

function Routes() {
  return (
    <Routes>
      <Route path="/">
        <Home />
      </Route>
      <Route path="/services">
        <Services />
      </Route>
      <Route path="/precare">
        <PreCare />
      </Route>
      <Route path="/aftercare">
        <Aftercare />
      </Route>
      <Route path="/policies">
        <Policies />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
    </Routes>
  );
}

export default Routes;
