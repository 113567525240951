import React from "react";
import "../css/Main.css";
import Monogram from "../images/Monogram.png";
import Filigree from "../images/Filigree 2(cream).png";

function Home() {
  return (
    <div className="is-preload">
      {/* Wrapper */}
      <div id="wrapper">
        {/* Header */}
        <header id="header">
          <div className="logo">
            <img id="logo-left" src={Filigree} alt="logo-left" />
            <img id="brand-logo" src={Monogram} alt="brand-logo" />
            <img id="logo-right" src={Filigree} alt="logo-right" />
          </div>
          <div className="content">
            <div className="inner">
              <h1>Rise In Glory</h1>
              <div>
                <p>
                  Creating natural hyper-realistic results and restoring
                  confidence with an all-inclusive approach to cosmetic
                  tattooing.
                </p>
              </div>
              <div>
                <p>Let's celebrate you.</p>
              </div>
            </div>
          </div>
          <nav>
            <ul>
              <li>
                <a href="/services">Services</a>
              </li>
              <li>
                <a href="/precare">Pre-Care</a>
              </li>
              <li>
                <a href="/aftercare">Aftercare</a>
              </li>
              <li>
                <a href="/policies">Policies</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </nav>
        </header>
      </div>
    </div>
  );
}

export default Home;
