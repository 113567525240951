import React, { useEffect, useRef, useState } from "react";
import "../css/Main.css";
import Daggers from "../images/Daggers X Green.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import RiseInGloryApi from "../api/riseInGloryApi.js";

function Contact() {
  const [focused, setFocused] = useState(true);
  const [formData, setFormData] = useState({});
  const [messageSent, setMessageSent] = useState(false);
  const [error, setError] = useState(false);

  const errorMessage = "Please fill in the required fields.";

  /** useRef and useEffect to make checkbox functional on click */

  const inputRef = useRef();

  useEffect(() => {
    if (focused) {
      inputRef.current.focus();
    } else {
      inputRef.current.blur();
    }
  }, [focused]);

  const toggleFocus = () => {
    setFocused((prev) => !prev);
  };

  const unFocus = () => {
    setTimeout(() => {
      if (focused) setFocused(false);
    }, 300);
  };

  const focus = () => {
    if (!focused) setFocused(true);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((data) => ({
      ...data,
      [name]: type === "checked" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form behavior

    // Collect data from form
    const {
      name,
      phone,
      email,
      message,
      priorServices,
      deposits, // Make sure this matches the corrected field name
      ...checkboxes
    } = formData;

    // Create a formatted object to send
    const formattedData = {
      name,
      phone,
      email,
      message,
      priorServices,
      deposits,
      services: Object.keys(checkboxes).filter((key) => checkboxes[key]),
    };

    // Check for required fields
    if (!name || !phone || !email || !priorServices || !deposits) {
      setError(true); // Display the error message
      return;
    }

    // Clear error and set success message state
    setError("");
    setMessageSent(true);

    setTimeout(() => {
      // Reset success message state after 10 seconds
      setMessageSent(false);
    }, 10000);

    console.log(formattedData);

    try {
      // Send formatted data to the API
      await RiseInGloryApi.sendEmail(formattedData);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  // console.log("Form Data", formData);

  return (
    <div className="is-preload">
      <div id="wrapper">
        <div id="main">
          <article id="contact">
            <div className="button-container">
              <button id="closeBtn">
                <a id="closeBtnA" href="/">
                  <img src={Daggers} alt="Close" />
                </a>
              </button>
            </div>
            <h2 className="major">Contact</h2>
            <form method="" id="contact-form" action="">
              <div className="fields">
                <div className="field">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter your name..."
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="field">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    placeholder="Enter your phone number..."
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="field">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Enter your email..."
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="field">
                  <h3>Service Type</h3>
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="traditional-makeup"
                      name="traditional-makeup"
                      ref={inputRef}
                      onBlur={unFocus}
                      onFocus={focus}
                      onClick={toggleFocus}
                      onChange={handleChange}
                    />
                    <label htmlFor="traditional-makeup">
                      Traditional Makeup
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="brow-lamination"
                      name="brow-lamination"
                      ref={inputRef}
                      onBlur={unFocus}
                      onFocus={focus}
                      onClick={toggleFocus}
                      onChange={handleChange}
                    />
                    <label htmlFor="brow-lamination">Brow Lamination</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="brow-tint"
                      name="brow-tint"
                      ref={inputRef}
                      onBlur={unFocus}
                      onFocus={focus}
                      onClick={toggleFocus}
                      onChange={handleChange}
                    />
                    <label htmlFor="brow-tint">Brow Tint</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="nano-brows"
                      name="nano-brows"
                      ref={inputRef}
                      onBlur={unFocus}
                      onFocus={focus}
                      onClick={toggleFocus}
                      onChange={handleChange}
                    />
                    <label htmlFor="nano-brows">Nano Brows</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="powder-brows"
                      name="powder-brows"
                      ref={inputRef}
                      onBlur={unFocus}
                      onFocus={focus}
                      onClick={toggleFocus}
                      onChange={handleChange}
                    />
                    <label htmlFor="powder-brows">Powder Brows</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="lip-blush"
                      name="lip-blush"
                      ref={inputRef}
                      onBlur={unFocus}
                      onFocus={focus}
                      onClick={toggleFocus}
                      onChange={handleChange}
                    />
                    <label htmlFor="lip-blush">Lip Blush</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="freckles"
                      name="freckles"
                      ref={inputRef}
                      onBlur={unFocus}
                      onFocus={focus}
                      onClick={toggleFocus}
                      onChange={handleChange}
                    />
                    <label htmlFor="freckles">Freckles</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="lash-enhancement"
                      name="lash-enhancement"
                      ref={inputRef}
                      onBlur={unFocus}
                      onFocus={focus}
                      onClick={toggleFocus}
                      onChange={handleChange}
                    />
                    <label htmlFor="lash-enhancement">Lash Enhancement</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="eyeliner"
                      name="eyeliner"
                      ref={inputRef}
                      onBlur={unFocus}
                      onFocus={focus}
                      onClick={toggleFocus}
                      onChange={handleChange}
                    />
                    <label htmlFor="eyeliner">Eyeliner</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="paramedical"
                      name="paramedical"
                      ref={inputRef}
                      onBlur={unFocus}
                      onFocus={focus}
                      onClick={toggleFocus}
                      onChange={handleChange}
                    />
                    <label htmlFor="paramedical">Paramedical</label>
                  </div>
                </div>

                <div className="field">
                  <h3>Prior Services</h3>
                  <p>Have you had permanent makeup done?</p>

                  <select
                    name="priorServices"
                    id="priorServices"
                    onChange={handleChange}
                    required
                  >
                    <option value="">--Please choose an option--</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <div className="field">
                  <h3>Deposits</h3>
                  <p>
                    I acknowledge that to book an appointment there will be a
                    non-refundable deposit. Terms and conditions will be
                    discussed during your free consultation.
                  </p>

                  <select
                    name="deposits"
                    id="deposits"
                    onChange={handleChange}
                    required
                  >
                    <option value="">--Please choose an option--</option>
                    <option value="yes-deposit">
                      Yes- I acknowledge a deposit will be required.
                    </option>
                    <option value="no-deposit">
                      No- I am not interested in making an appointment.
                    </option>
                    <option value="unsure-deposit">
                      I have questions in regards to the deposit.
                    </option>
                  </select>
                </div>

                <div className="field">
                  <label htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Write a message..."
                    rows="4"
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <ul className="actions">
                <li>
                  <input
                    id="send-message"
                    type="submit"
                    value="Send Message"
                    className="primary"
                    onClick={handleSubmit}
                  />
                </li>
                <li>
                  <input type="reset" value="Reset" />
                </li>
              </ul>
            </form>

            {error && <div id="errorMessage">{errorMessage}</div>}
            {messageSent && <div id="confirmationMessage">Message sent!</div>}

            <ul className="icons">
              <li>
                <a
                  href="https://www.facebook.com/kaylee.taylor.9406417"
                  id="facebook-icon"
                  className="icon brands"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                  <span className="label">Facebook</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/riseinglorybeauty/"
                  id="instagram-icon"
                  className="icon brands"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                  <span className="label">Instagram</span>
                </a>
              </li>
            </ul>
          </article>
        </div>
      </div>
    </div>
  );
}

export default Contact;
